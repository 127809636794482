<template>
  <div class="row px-4">
    <div class="bg-white w-full rounded-lg mt-4 mb-3 px-4 py-2.5">
      <label> <span @click='cancel()' class="text-capitalize cursor-pointer text-gray-900 font-semibold">
          Category</span> <i class="fa-solid fa-chevron-right mx-2 text-gray-500 text-xs font-normal"></i> <span
          class="text-gray-500 font-normal capitalize"> Add New</span>
      </label>
      <h4 class="text-xl capitalize">New Category</h4>
    </div>
    <div class="bg-white w-full rounded-lg mt-4 mb-3 px-4 py-2.5">
      <h4 class="font-bold text-xl py-2.5">Create New Category</h4>
      <div class="w-1/2 flex-col">
        <div>
          <label class="block">Category Name</label>
          <input type="text" class="px-2.5 py-2.5 border rounded-lg w-full" id="name" placeholder="type category name.."
            v-model="name" /> 
        </div>
        <div class="mt-2">
          <label class="block">Item’s Type</label>
          <t-select class="px-2.5 py-2.5" id="status" v-model="item_type" :options="options">
          </t-select>
        </div>
        <div class="mt-3 mb-4">
          <t-checkbox type="checkbox" id="is_active" v-model="is_active" />
          <label class="form-check-label" for="is_active">Is Active</label>
        </div>

        <div class="flex justify-start pb-3">
          <button class="bg-blue-700 text-white rounded-lg px-3 py-2" @click="addCategory">Save</button>
          <button class="bg-white text-gray-500 rounded-lg border px-3 py-2 ml-4" @click="cancel">Cancel</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import constant from '../../store/constant';
import { EventBus } from '../../event-bus'

const itemType = Object.entries(constant.ITEM_TYPES).map((val) => ({
  text: val[1],
  value: val[1].toLowerCase(),
}));
export default {
  name: 'AddCategory',
  data() {
    return {
      name: '',
      is_active: false,
      item_type: 'Select Item Type',  
      options: [
      { text: 'Select Item Type', value: null },
      ...itemType,
    ],
    };
  },
  computed: {
    ...mapState({
      successMessage: (state) => state.category.successMessage,
      errorMessage: (state) => state.category.errorMessage,
    }),
  },
  watch: {
    successMessage: function () {
      if (!this.successMessage) return;
      //  this.alertMsg(this.successMessage, 'success');
      EventBus.$emit('show-toast', {
        message: this.successMessage,
        type: 'success',
      });
      this.$router.push('/category');
    },
    errorMessage: function () {
      if (!this.errorMessage) return;
      //this.alertMsg(this.errorMessage, 'error');
      EventBus.$emit('show-toast', {
        message: this.errorMessage,
        type: 'error',
      });
    },
  },
  methods: {
    ...mapActions('category', ['createCategory']),

    async addCategory() {
      const payload = {
        name: this.name,
        item_type: this.item_type,
        is_active: this.is_active,
      };
      this.createCategory(payload);
    },

    cancel() {
      this.$router.push('/category');
    }
  },
};
</script>
